<template>
  <v-scale-transition>
    <kits-panel :kits-items="dmtKits">
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        DMT Kits
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
export default {
  components: {
    KitsPanel: () =>
      import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  computed: {
    dmtKits: function () {
      const menus = [
        {
          icon: 'mdi-cast-connected',
          path: '/support/dmt_reports',
          title: 'Reports',
          desc: 'Essential reports.',
        },
        {
          icon: 'mdi-account-star-outline',
          path: '/support/ad_achievement',
          title: 'AD Achievement',
          desc: 'Area Dealer achievements.',
        },
        {
          icon: 'mdi-account-off',
          path: '/support/dealer_terminated_list',
          title: 'Dealer List',
          desc: 'Dealer Demoted/Terminated List.',
        },
        {
          icon: 'mdi-clipboard-list-outline',
          path: '/support/pushup_user_list',
          title: 'User List',
          desc: 'User Demoted/Terminated List.',
        },
        {
          icon: 'mdi-sim',
          path: '/support/simpack_list',
          title: 'Simpack List',
          desc: 'Dealer Simpack List.',
        },
        {
          icon: 'mdi-clipboard-text-clock-outline',
          path: '/support/topup_history',
          title: 'Topup History',
          desc: 'Topup History last 3 Month.',
        },
        {
          icon: 'mdi-newspaper-plus',
          path: '/support/dealer_news',
          title: 'Dealer News',
          desc: 'Adding News for dealer.',
        },
        {
          icon: 'mdi-account-arrow-up-outline',
          path: '/support/set_introducer',
          title: 'Set Introducer',
          desc: 'For subscriber without introducer.',
        },
        {
          icon: 'mdi-account-reactivate-outline',
          path: '/support/activate_user',
          title: 'Activate User',
          desc: 'Activate Terminated User.',
        },
        {
          icon: 'mdi-file-document-check',
          path: '/support/ssm_verification',
          title: 'SSM Verification',
          desc: 'SSM Document Verification',
        },
      ]

      return menus
    },
  },
}
</script>
